<template>
    <v-hover v-slot="{ hover }">
      <v-card class="custom-card">
        <div class="card-header">
          <table>
            <tr>
              <td><span class="text-left card-title">{{ item.name }}</span></td>
              <td><span class="text-right" style="color: orange" v-show="!nonBetaNames.includes(item.name)">Beta</span></td>
            </tr>
        </table>
        </div>
        <div class="card-content">
          <table class="custom-table">
            <tr v-for="(value, key) in getItemData(item)" :key="key">
              <td class="text-left">{{ key }}</td>
              <td class="text-right">
                <v-fade-transition>
                  <v-btn
                    v-if="hover && value && value !== 0"
                    icon
                    x-small
                    @click="copyToClipboard(value)"
                    class="copy-button mr-1"
                  >
                    <v-icon x-small>mdi-content-copy</v-icon>
                  </v-btn>
                </v-fade-transition>
                <span :style="key === 'performance' ? { color: getPerformanceColor(value) } : {}">
                  <v-icon x-small v-if="key === 'performance' && value && value !== 0" style="color: inherit">mdi-trending-{{ getPerformanceIcon(value) }}</v-icon>
                  {{ value }}
                </span>
                <InfoHover v-if="key === 'reach' || key === 'cover' || key === 'frequency' " :iconColour="'rgba(255, 157, 0, 0.44)'" :isBeta="true" />

              </td>
            </tr>
          </table>
        </div>
        <PlayoutMiniChart 
          v-if="item.miniChartData"
          :miniData="item.miniChartData"
          class="mini-chart"
        />
        <v-progress-linear style="position: absolute;bottom: 0px; opacity: 0.5;"
          height="3"
          :color="getPerformanceColor(item.performance)"
          :value="item.performance || 0"
          stream
        ></v-progress-linear>
      </v-card>
    </v-hover>
  </template>
  
  <script>
  import PlayoutMiniChart from './PlayoutMiniChart.vue';
  import InfoHover from '@/components/_theme/InfoHover.vue';
  
  export default {
    name: 'PlayoutKPI',
    components: {
      PlayoutMiniChart,
      InfoHover
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
    return {
      nonBetaNames: ['Impacts', 'Impacts/Impressions', 'Plays', 'Hours'],
    };
  },
    methods: {
      getItemData(item) {
        var headers = Object.keys(item);
        var values = Object.values(item);
        var data = {};
        for (var i = 0; i < headers.length; i++) {
          if (headers[i] === 'name' || headers[i] === 'miniChartData') continue;
          var value = values[i];
          if (value) {
            const isPercentage = headers[i] === 'performance';
            const maxDecimal = isPercentage ? 2 : 0 || headers[i] === 'frequency' ? 5 : 0;
            value = value.toString().replace(/,/g, '');
            value = parseFloat(value).toLocaleString('en-GB', {
              maximumFractionDigits: maxDecimal,
            });
            value = isPercentage ? `${value}%` : value;
          }
          data[headers[i]] = value;
        }
        return data;
      },
      copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
          this.$emit('copied');
        });
      },
      getPerformanceColor(performance) {
        const value = parseFloat(performance);
        if (isNaN(value)) return 'inherit';
        
        const hue = Math.min(value, 100) * 1.2; // 0 to 120 (red to green)
        return `hsl(${hue}, 100%, 40%)`;
      },
      getPerformanceIcon(value) {
        value = parseFloat(value);
        if (value > 100) {
          return 'up';
        }
        else if (value > 90) {
          return 'neutral';
        }
        else {
          return 'down';
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-card {
    background: var(--v-sidebarColorLight-darken2);
    margin: 0px;
    overflow: hidden;
    height: 100%;
    position: relative;
  }
  
  .card-header {
    padding: 22px 22px 0px;
  }
  
  .card-title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 3px;
    color: grey;
    font-size: 14px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .card-content {
    padding: 0px 20px 20px 20px;
    position: relative;
    z-index: 2;
  }
  
  .custom-table {
    width: 100%;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  .text-right {
    text-align: right;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 20px;
  }
  
  .text-left {
    text-align: left;
  }
  
  .mini-chart {
    position: absolute;
    width: calc(100% + 34px);
    height: 100%;
    left: -23px;
    bottom: -15px;
  }
  
  .copy-button {
    opacity: 0.7;
    transition: opacity 150ms ease-out;
  }
  
  .copy-button:hover {
    opacity: 1;
  }
  </style>